import React, { Component } from "react"
import Headermenu from "../containers/RealestatePage2/HeaderMenuBS"
import Banner from "../containers/RealestatePage2/Banner"
import About from "../containers/RealestatePage2/About"
import Specifications from "../containers/RealestatePage2/Specifications"
import Features from "../containers/RealestatePage2/Features"
import Consultation from "../containers/RealestatePage2/Consultation"
import Amenities from "../containers/RealestatePage2/Amenities"
import OurAgents from "../containers/RealestatePage2/OurAgents"
import Stats from "../containers/RealestatePage2/Stats"
import Gallery from "../containers/RealestatePage2/Gallery"
import Testimonials from "../containers/RealestatePage2/Testimonials"
import Footer from "../containers/RealestatePage2/Footer"
import GlobalStyle from "../containers/RealestatePage2/Common/global-styles"
import Fonts from "../containers/RealestatePage2/Common/fonts"
import "../components/layout.css"
import SEO from "../components/seo"
import BuyNow from "../components/BuyNow"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

class RealestatePage2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenLightBox: false,
      lightBoxImages: [],
      photoIndex: 0,
    }
    this.openLightBox = this.openLightBox.bind(this)
  }

  openLightBox(imageArray, idx) {
    const images = []
    for (const [index, value] of imageArray.GalleryImageWrapper.entries()) {
      console.log(index)
      images.push(value.GalleryImage.childImageSharp.fluid.src)
    }
    this.setState({
      isOpenLightBox: true,
      lightBoxImages: images,
      photoIndex: idx,
    })
  }

  render() {
    return (
      <div>
        {
          <div>
            <Fonts />
            <GlobalStyle />
            <main>
              <SEO
                title="Real Estate Page 2 | Gatsby Landing Page Templates"
                description="Buy unique and professional React GatsbyJS Real Estate landing page templates from Topaz"
              />
              <BuyNow />
              <Headermenu />
              <Banner />
              <About />
              <Specifications />
              <Features />
              <Consultation />
              <Amenities />
              <OurAgents />
              <Stats />
              <Gallery openLightBox={this.openLightBox} />
              <Testimonials />
            </main>
            <Footer />
          </div>
        }
        {this.state.isOpenLightBox && (
          <Lightbox
            mainSrc={this.state.lightBoxImages[this.state.photoIndex]}
            nextSrc={
              this.state.lightBoxImages[
                (this.state.photoIndex + 1) % this.state.lightBoxImages.length
              ]
            }
            prevSrc={
              this.state.lightBoxImages[
                (this.state.photoIndex + this.state.lightBoxImages.length - 1) %
                  this.state.lightBoxImages.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpenLightBox: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex +
                    this.state.lightBoxImages.length -
                    1) %
                  this.state.lightBoxImages.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + 1) %
                  this.state.lightBoxImages.length,
              })
            }
          />
        )}
      </div>
    )
  }
}

export default RealestatePage2
